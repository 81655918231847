import * as React from "react";
import Layout from "../components/layout";
import { Link } from "gatsby";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const NotFoundPage = () => {
  return (
    <Layout pageTitle="Page Not found!!">
      <div className="bg-greenLight">
        <div className="max-w-screen-xl mx-auto py-12 lg:py-24 px-4">
          <h2 className="text-greenDark text-3xl text-center font-light font-quick tracking-tight">
            Page Not Found !
          </h2>
          <div className="mt-10 lg:mt-14">
            <Link to="/">
              <div className="max-w-xs mx-auto flex justify-center justify-items-center items-center p-5 border rounded-2xl text-greenDark hover:text-white hover:bg-greenDark border-greenDark space-x-4">
                <ArrowLeftIcon
                  className="h-[65px] w-[65px] text-green bg-[#DCE7E4] p-[20px] rounded-full"
                  aria-hidden="true"
                />
                <h6 className="text-md font-bold font-quick text-[18px]">
                  Go Home
                </h6>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFoundPage;
